import axios from "axios";
import React, { useRef } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Breadcrumb, BreadcrumbItem, Button, Card, Col, Container, Form, InputGroup, Row, Tab, Tabs } from "react-bootstrap";
import AudioStreaming from "../../../../lib/audioStreaming";
import { useNavigate } from "react-router-dom";


let play_latency = 0.042;
function WebAPI_VC(props){
    const navigate = useNavigate();
    const [strings, setStrings] = useState([["", ""]]);
    const [result, setResult] = useState("");

    const api_code = "vc";
    const [api_version, setAPIVersion] = useState("");

    const [useVoicefilter, setVoiceFilter] = useState(true);

    const [VCUpload, setVCUpload] = useState("");
    const [VCLearnUpload, setVCLearnUpload] = useState("");
    const [VFUpload, setVFUpload] = useState("");

    const [VC_RT_models, setVCRTModels] = useState([]);
    const [VC_RT_api_version, setVCRT_apiversion] = useState("");
    const [VC_RT_model_sel, setVCRT_model_sel] = useState("");
    const [VC_RT_token, setVCRTToken] = useState("");
    const [useNoiseSupp, setUseNoiseSupp] = useState(false);
    const [sr_range, setSRRange] = useState(5);
    const [bfs_range, setBFSRange] = useState(5);

    const [VC_models, setVCModels] = useState([]);
    const [VC_api_version, setVC_apiversion] = useState("");
    const [VC_model_sel, setVC_model_sel] = useState("");

    const [VC_queuelog, setVCQueueLog] = useState("");
    const [VC_Batchqueuelog, setVCBatchQueueLog] = useState([]);
    const [VC_batchQueueTimer, setVCBatchQueueTimer] = useState(false);
    const vc_queueloguid = useRef(0);
    const status = {ready :"준비", running:"진행중", finished:"완료", failed:"실패"};


    const [VC_Learnqueuelog, setVCLearnQueueLog] = useState("");
    const [VF_queuelog, setVFQueueLog] = useState("");
    

    const [socket, setSocket] = useState(null);
    const [stream, setStream] = useState(null);
    const [msg, setMsg] = useState([]);
    const [bufferSize, setBufferSize] = useState(2048);
    const [sampleRate, setSampleRate] = useState(16000);
    const [rec_state, setRecState] = useState(false)
    const [rec_components, setRecComponents] = useState({source:null, processor:null});
    const [btnContent, setbtnContent] = useState("Start");
    const [network_latency, setNetworkLatency] = useState([0]);
    const [process_latency, setProcessLatency] = useState(0);
    const [ping, setPing] = useState(0);
    const [srv_latency_, setSrvLatency] = useState(0);
    const [postProcessing_latency, setPostProcessingLatency] = useState(0);
    const [decoderReady, setDecoderReady] = useState(false);
    const [wsinfo, setWsinfo] = useState({wstoken:"", server:""});
    const setPlayLatency = (val)=>{
        play_latency = val;
    }
    let net_lat = [0];

    const switchRecording = (e)=>{
        console.log(rec_state);
        if(rec_state==true){
            rec_components.source.disconnect();
            rec_components.processor.disconnect();
            setStream(null);
            //setRecComponents({source:null, processor:null});
            stream.getTracks().forEach((track)=>{track.stop();})
            if(socket != null)
                socket.disconnect();
            setSocket(null);
        }else{
            console.log("rec start");
            /*
            if(decoderReady == false){
                alert("Decoder is not ready");
                return;
            }*/
            onRecord();
        }
            setRecState(!rec_state);
    }
    const downloadLink = (url) => {      
        const a = document.createElement('a');
        a.href = url;
        a.download="";
        document.body.appendChild(a);
        a.click();
        setTimeout((_) => {
            window.URL.revokeObjectURL(url);
        }, 60000);
        a.remove();
    };
    const onRecord = ()=>{
        if(rec_state == true)
            return;
            navigator.mediaDevices.getUserMedia({audio:{sampleRate:sampleRate, channelCount:1, noiseSuppression:useNoiseSupp}, video:false})
        .then((mediaStream)=>{setStream(mediaStream);}).catch(function(err) { console.log(err.name + ": " + err.message); })
    }

    const onChangeVCRTAPIVersion = (e)=>{
        setVCRT_apiversion(e.target.value);
        getModelList(e.target.value, setVCRTModels);
    };

    const onChangeVCRTModel = (e)=>{
        setVCRT_model_sel(e.target.value);
    }; 
    
    const onChangeVCRT_Samplerate = (e)=>{
        let size = [8000, 16000, 22050, 24000, 32000, 44100, 48000, 96000];
        let step = e.target.value;
        console.log("SR : " ,size[step-1]);
        setSampleRate(size[step-1]);
        setSRRange(step);
    };
    const onChangeVCRT_BufferSize = (e)=>{
        let size = [512, 1024, 1536, 2048, 3072, 4096, 8192, 16384];
        let step = e.target.value;
        console.log("BFS : ", size[step-1]);
        setBufferSize(size[step-1]);
        setBFSRange(step);
    };
    const handleSupp = (e)=>{
        setUseNoiseSupp(!useNoiseSupp);    
    }
    
    const onChangeVCAPIVersion = (e)=>{
        //setVCRT_apiversion(e.target.value);
        getModelList(e.target.value, setVCModels);
    };

    const onChangeVCModel = (e)=>{
        setVCRT_model_sel(e.target.value);
    };
    
    const onChangeAPIVersion = (e)=>{
        setAPIVersion(e.target.value);
    };
    
    const addMultiwordsRow = ()=>{
        setStrings([...strings, ["", ""]]);
    }
    const addBatchwordsRow = ()=>{
        setStrings([...strings, ""]);
        setStrings([...strings, "",""]);
    }

    const onLoadVCFile = (e)=>{
        setVCUpload(e.target.files);
    }
    const onLoadVCLearnFile = (e)=>{
        setVCLearnUpload(e.target.files);
    }

    const onLoadVFFile = (e)=>{
        setVFUpload(e.target.files);
    }

    const handleVF = () =>{
        setVoiceFilter(!(useVoicefilter));
    };

    const getModelList = (api_version, func) =>{
        if(api_version == ""){
            alert("API버전을 선택해주세요!");
            return;
        }
        axios.get('/rest/getAIModels',{params:
            {
                service_uid : 4,
                api_version : api_version

            }}).then(response => {
            if(response.data.logged === false || response.data == ''){
                alert("목소리 목록을 가져오는데 실패했습니다.");
            }else{
                if(response.data.result == "success"){
                    func(response.data.data);
                }else{
                    alert("목소리 목록을 가져오는데 실패했습니다.");
                    //navigate("/");

                props.setLoading(false);
            }
        }
        }).catch(
            error=>{
                alert("목소리 목록을 가져오는데 실패했습니다.");
                console.log(error);
                props.setLoading(false);
                //navigate("/");
            }
        );
    }

    const Request_RealtimeToken = ()=>{
        if(VC_RT_api_version ==""){
            alert("API버전을 선택해주세요!");
            return;
        }
        if(VC_RT_model_sel ==""){
            alert("목소리를 선택해주세요!");
            return;
        }
        props.setLoading(true);
        let data = {
            service_uid : 4,
            model_name : VC_RT_model_sel,
            'api_version' : VC_RT_api_version,
            api_code : 'vc_realtime'
        };
        /*
        let json_data = JSON.stringify(data);
        const formData = new FormData();
        formData.append("body", json_data);
        */
        axios.post('/rest/realtimeService', data).then((response)=>{
            if(response.data.result == 'success'){
                setVCRTToken(response.data.wstoken);
                console.log(response.data);
                //Request_RealtimeWSAuth(response.data.wstoken, response.data.server);
                setWsinfo(response.data);
            }else{
                if(response.data.data != undefined){
                    if(response.data.data.error == 425||response.data.data.error == 427){
                        alert("크레딧이 모두 소진되었습니다. 크레딧을 구입해 주세요.");
                        window.open('/subscribe');
                    }else{
                        alert("요청 실패! 잠시후 다시시도해 주시거나 고객센터로 문의해주세요");
                    }
                }else{
                    alert("요청 실패! 잠시후 다시시도해 주시거나 고객센터로 문의해주세요");
                }
            }
            props.setLoading(false);
        }).catch((error)=>{
            alert("요청 실패! 잠시후 다시시도해 주시거나 고객센터로 문의해주세요");
            props.setLoading(false);
        });

    }
    const getVCBatchQueueLog = () =>{
        axios.get('/rest/getBatchQueueList',{params:
            {
                service_uid : 4
            }}).then(response => {
            if(response.data.logged === false || response.data == ''){
                alert("작업목록을 가져오는데 실패했습니다.");
                navigate("/");
            }else{
                if(response.data.result == "success"){
                    setVCBatchQueueLog(response.data.data);
                    if(vc_queueloguid.current > 0){
                        for(var i=0; i<response.data.data.length; i++){
                            if(response.data.data[i].uid == vc_queueloguid.current)
                                setVCQueueLog(response.data.data[i].log);
                        }
                    }
                }else{
                    alert("작업목록을 가져오는데 실패했습니다.");
                    navigate("/");
                }
                props.setLoading(false);
            }
        }).catch(
            error=>{
                alert("작업목록을 가져오는데 실패했습니다.");
                console.log(error);
                props.setLoading(false);
                navigate("/");
            }
        );
    }
    const RequestBatch = ()=>{
        if(!VCUpload){
            alert("압축파일을 선택해주세요!");
            return;
        }
        if(!api_version){
            alert("API버전을 선택해주세요!");
            return;
        }
        if(VCUpload[0].type !== 'application/zip' && VCUpload[0].type !== 'application/x-zip-compressed'){
            alert("ZIP 파일만 업로드 가능합니다."+VCUpload[0].type);
            return;
        }
        props.setLoading(true);
        let data = {
            service_uid : 4,
            api_code : api_code,
            api_subname : "infervc",
            'api-version' : api_version,
            voicefilter : useVoicefilter == true ? "1" : "0",
        };
        let json_data = JSON.stringify(data);
        const formData = new FormData();
        formData.append("body", json_data);
        formData.append("file", VCUpload[0])
        axios.post('/rest/webapi',formData).then((response)=>{
            if(response.data.result == 'success'){
                getVCBatchQueueLog();
                vc_queueloguid.current = Number(response.data.data.log_id);
                alert("작업이 시작되었습니다. 작업목록을 확인해주세요");
            }else{
                if(response.data.data != undefined){
                    if(response.data.data.error == 425||response.data.data.error == 427){
                        alert("크레딧이 모두 소진되었습니다. 크레딧을 구입해 주세요.");
                        window.open('/subscribe');
                    }else{
                        alert("요청 실패! 잠시후 다시시도해 주시거나 고객센터로 문의해주세요");
                    }
                }else{
                    alert("요청 실패! 잠시후 다시시도해 주시거나 고객센터로 문의해주세요");
                }
            }
            props.setLoading(false);
        }).catch((error)=>{
            alert("요청 실패! 잠시후 다시시도해 주시거나 고객센터로 문의해주세요");
            props.setLoading(false);
        });

    }

    const resetConfig = (isBatch)=>{
        if(isBatch)
            setStrings(["", ""]);
        else
            setStrings([["", ""]]);
        setAPIVersion("");
        setResult("");
    }
    
    useEffect(()=>{
        console.log("strings :"+JSON.stringify(strings));
        console.log("strings[0] :"+strings[0]);
        //console.log("strings[0][0] :"+strings[0][0]);
        //console.log("strings[0][1] :"+strings[0][1]);
    }, [strings]);
    useEffect(()=>{

    },[VC_RT_api_version, VC_RT_model_sel, VC_RT_models, VC_api_version, VC_model_sel, VC_models]);
    useEffect(()=>{
        if(rec_state == true){
            setbtnContent("Stop");
            
        }else{
            //if(stream != null && rec_components.source != null){
                if(socket != null)
                    socket.disconnect();
               // setbtnContent("Start");
            //}
        }
        
    }, [rec_state, stream, socket]);
    useEffect(()=>{
            if(wsinfo.server != ""){
                onRecord();
                setRecState(true);
            }else{
                if(setRecState==true)
                    switchRecording();
            }
    }, [wsinfo]);
    useEffect(()=>{
        if(VC_batchQueueTimer == true){
            const timer = setInterval(()=>{
                getVCBatchQueueLog();
            }, 1000);
            return ()=>clearInterval(timer);
        }
    }, [VC_batchQueueTimer])
    return(
        <>
        <AudioStreaming wsinfo = {wsinfo} play_latency={play_latency} setPlayLatency={setPlayLatency} net_lat={net_lat} decoderReady={decoderReady} setDecoderReady={setDecoderReady} srv_latency_={srv_latency_} setSrvLatency={setSrvLatency} postProcessing_latency={postProcessing_latency} setPostProcessingLatency={setPostProcessingLatency} ping={ping} setPing={setPing} network_latency={network_latency} setNetworkLatency={setNetworkLatency} process_latency={process_latency} setProcessLatency={setProcessLatency} socket={socket} setSocket={setSocket} stream={stream} setStream={setStream} bufferSize={bufferSize} setBufferSize={setBufferSize} sampleRate={sampleRate} setSampleRate={setSampleRate} rec_components={rec_components} setRecComponents={setRecComponents}/>
            <Breadcrumb className="section-content">
                <Breadcrumb.Item href="/index">홈</Breadcrumb.Item>
                <Breadcrumb.Item href="/mypage/dashboard">
                    마이페이지
                </Breadcrumb.Item>
                <Breadcrumb.Item active>음성변환 웹 API</Breadcrumb.Item>
            </Breadcrumb>
            <section className="sections-white">
                <Container className="section-content">
                    <Tabs onSelect={(key)=>{resetConfig(key=='batchWords')}}>
                        <Tab eventKey="realtimeVC" title="실시간 음성변환"  className="p-3">
                            <Row>
                                <Col lg={6}>
                                    <Form.Group>
                                        <Row>
                                            <span><strong>이용시간 : </strong><strong>00:00</strong><strong> / </strong><strong>12:34</strong></span>
                                        </Row>
                                        <Row className="mt-3">
                                            <InputGroup>
                                                <Form.Label>음질</Form.Label>
                                                <Form.Range  min="1" max="8" step="1"  value={sr_range} onChange={(e)=>{onChangeVCRT_Samplerate(e)}} disabled={rec_state}/>
                                            </InputGroup>
                                        </Row>
                                        <Row>
                                            <InputGroup>
                                                <Form.Label>지연시간</Form.Label>
                                                <Form.Range min="1" max="8" step="1" value={bfs_range} onChange={(e)=>{onChangeVCRT_BufferSize(e)}} />
                                            </InputGroup>
                                        </Row>
                                        <Row className="my-2">
                                            <Form.Group>
                                                <Form.Check name="noisesupp" type="switch" checked={useNoiseSupp} onChange={handleSupp} label="노이즈 제거"></Form.Check>
                                            </Form.Group>
                                        </Row>
                                        <Row className="mt-3">
                                            <InputGroup>
                                                <Form.Select defaultValue={VC_RT_api_version} onChange={onChangeVCRTAPIVersion}>
                                                    <option value="" >API버전 선택</option>
                                                    <option value="2024-05-05">2024-05-05</option>
                                                </Form.Select>
                                                <Form.Select defaultValue={VC_RT_model_sel} onChange={onChangeVCRTModel}>
                                                    <option value="">목소리 선택</option>
                                                    {
                                                        VC_RT_models.map(function(a, idx){
                                                            return <option key={idx} value={a.model_name}>{a.model_name}{a.model_type == "base" ? "(기본모델)" : "사용자모델"}</option>
                                                        })
                                                    }
                                                </Form.Select>
                                            </InputGroup>
                                            <InputGroup>
                                                
                                            </InputGroup>
                                        </Row>
                                        <Row className="mt-4 text-end">
                                            <InputGroup className="d-block">
                                                { !rec_state ? 
                                                    <Button onClick={()=>{Request_RealtimeToken()}}>실시간 변환 시작</Button>
                                                    :
                                                    <Button onClick={()=>{switchRecording()}}>실시간 변환 중지</Button>
                                                }
                                                <Button variant="secondary" onClick={()=>{}}>녹음 시작</Button>
                                            </InputGroup>
                                        </Row>
                                        <Row className="mt-3">
                                            <Container className="mt-3 text-secondary">
                                                <Row>
                                                    <strong>※ API 버전별 특징 안내</strong>
                                                </Row>
                                                <Row className="fs-6 ps-2">
                                                    <Row>
                                                        <span>2024-05-05 : 한국지능정보사회진흥원의 AI Hub에서 제공하는 자료를 학습한 <br/>인공지능 모델을 이용합니다. 한국어 최적화 모델입니다.</span>
                                                    </Row>
                                                </Row>
                                            </Container>
                                        </Row>
                                    </Form.Group>
                                </Col>
                                <Col lg={6}>
                                    <Row>
                                        <strong>소리가 나오지 않을 때</strong>
                                    </Row>
                                </Col>
                            </Row>
                        </Tab>
                        <Tab eventKey="normalVC" title="음성변환"  className="p-3">
                            <Row>
                                <Col lg={6}>
                                    <Form.Group>
                                    <Row className="mt-3">
                                            <InputGroup>
                                                <Form.Label>음질</Form.Label>
                                                <Form.Range />
                                            </InputGroup>
                                        </Row>
                                        <Row>
                                            <Form.Group className="my-3">
                                                <Form.Label>오디오 압축파일 선택</Form.Label>
                                                <Form.Control type="file" accept="application/zip" id="zip_file" onChange={onLoadVCLearnFile}/>
                                            </Form.Group>
                                        </Row>
                                        <Row className="mt-3">
                                            <InputGroup>
                                                <Form.Select defaultValue={VC_api_version} onChange={onChangeVCAPIVersion}>
                                                    <option value="" >API버전 선택</option>
                                                    <option value="2024-05-05">2024-05-05</option>
                                                </Form.Select>
                                                <Form.Select defaultValue={VC_model_sel} onChange={onChangeVCModel}>
                                                    <option value="" >목소리 선택</option>
                                                    {
                                                        VC_models.map(function(a, idx){
                                                            return <option key={idx} value={a.uid}>{a.model_name}{a.model_type == "base" ? "(기본모델)" : "사용자모델"}</option>
                                                        })
                                                    }
                                                </Form.Select>
                                            </InputGroup>
                                            <InputGroup>
                                                
                                            </InputGroup>
                                        </Row>
                                        <Row className="my-2">
                                            <Form.Group>
                                                <Form.Check name="voicefilter" type="switch" checked={useVoicefilter} onChange={handleVF} label="AI가 목소리만 추출"></Form.Check>
                                            </Form.Group>
                                        </Row>
                                        {
                                            useVoicefilter ? 
                                                <Row>
                                                <InputGroup>
                                                        <Form.Select value={api_version} onChange={onChangeAPIVersion}>
                                                            <option value="" >API버전 선택</option>
                                                            <option value="2024-05-05">2024-05-05</option>
                                                        </Form.Select>
                                                    </InputGroup>
                                                </Row>
                                                :
                                                ""
                                        }
                                        <Row>
                                            <Container className="text-secondary">
                                                <Row>
                                                    <strong className="fs-6">※ AI가 목소리만 추출 버튼이 선택되면,<br/>AI가 등록된 목소리를 구별하여 사용자가 원하는 목소리만 학습합니다.</strong>
                                                </Row>
                                            </Container>
                                        </Row>
                                        <Row className="mt-4 text-end">
                                            <InputGroup className="d-block">
                                            <Button onClick={()=>{RequestBatch()}}>변환 시작</Button>
                                                <Button variant="secondary" onClick={()=>{resetConfig();}}>녹음 시작</Button>
                                            </InputGroup>
                                        </Row>
                                        <Row className="mt-5">
                                            <Form.Group>
                                                <Form.Label>상세로그</Form.Label>
                                                <Form.Control as="textarea"style={{backgroundColor:"white"}} rows={13} value={VC_queuelog} readOnly/>
                                            </Form.Group>
                                        </Row>
                                        <Row className="mt-3">
                                            <Container className="mt-3 text-secondary">
                                                <Row>
                                                    <strong>※ API 버전별 특징 안내</strong>
                                                </Row>
                                                <Row className="fs-6 ps-2">
                                                    <Row>
                                                        <span>2023-02-12 : 한국어 위키백과 자료를 학습한 인공지능 모델을 이용합니다. <br/>개념의 정의 등에 대한 단어 비교에 유리합니다.</span>
                                                    </Row>
                                                    <Row>
                                                        <span>2023-04-28 : 한국지능정보사회진흥원의 AI Hub에서 제공하는 자료를 학습한 <br/>인공지능 모델을 이용합니다. 뉴스기사 등에 등장하는 단어 비교에 유리합니다.</span>
                                                    </Row>
                                                </Row>
                                            </Container>
                                        </Row>
                                    </Form.Group>
                                </Col>
                                <Col lg={6}>
                                    <Form.Group className="h-100">
                                        <Form.Label>작업목록</Form.Label>
                                        <Container className="border py-3 m-lg-3  p-lg-3" style={{overflowY:"scroll", height:"700px"}}>
                                            {
                                                VC_Batchqueuelog.map(function(a, idx){
                                                    return(
                                                        <Row key={idx} className="mb-3  mx-auto" style={{height:"fit-content"}}>
                                                            <Card>
                                                                <Card.Body className=" px-1 py-3 p-lg-3">
                                                                    <Card.Title className="justify-content-between d-flex">
                                                                        <span>{a.request_id}</span>
                                                                        <span>
                                                                            { a.status == "running" ?
                                                                                <img style={{width:"20px", marginRight:"1rem"}} src={process.env.PUBLIC_URL + '/assets/loading_circle.gif'}/>
                                                                                :
                                                                                ""
                                                                            }
                                                                            {a.progress}%
                                                                        </span>
                                                                    </Card.Title>
                                                                    <div className="mb-2 justify-content-between d-flex">
                                                                        <span>
                                                                            <span className="fs-6">시작시각 : {a.start_date}</span><br/>
                                                                                {
                                                                                    a.status == "finished" ?
                                                                                    <span className="fs-6">종료시각 : {a.finished_date}</span>
                                                                                    :
                                                                                    ""
                                                                                }
                                                                        </span>
                                                                        <span style={{minWidth:"40px"}}>{status[a.status]}</span>
                                                                    </div>
                                                                    <Row>
                                                                        <span>
                                                                            <Button variant="outline-secondary" className="me-3" onClick={()=>{vc_queueloguid.current = a.uid; setVCQueueLog(a.log)}} >로그보기</Button>
                                                                            {a.status == "finished" ?
                                                                                <Button variant="outline-primary" onClick={()=>{downloadLink(a.download)}} >다운로드</Button>
                                                                                :
                                                                                ""
                                                                            }
                                                                        </span>
                                                                    </Row>
                                                                </Card.Body>
                                                            </Card>
                                                        </Row>
                                                    );
                                                })
                                            }
                                        </Container>
                                    </Form.Group>
                                </Col>
                            </Row>
                        </Tab>
                        <Tab eventKey="learningModel" title="목소리학습"  className="p-3">
                            <Row>
                                <Col lg={6}>
                                    <Form.Group>
                                        <Row>
                                            <Form.Label>API버전 선택</Form.Label>
                                        </Row>
                                        <Row>
                                            <InputGroup>
                                                <Form.Select value={api_version} onChange={onChangeAPIVersion}>
                                                    <option value="" >API버전 선택</option>
                                                    <option value="2024-01-08" >2024-01-08</option>
                                                    <option value="2023-08-23" >2023-08-23</option>
                                                    <option value="2023-04-25" >2023-04-25</option>
                                                </Form.Select>
                                            </InputGroup>
                                        </Row>
                                        <Row>
                                            <Form.Group className="my-3">
                                                <Form.Label>오디오 압축파일 선택</Form.Label>
                                                <Form.Control type="file" accept="application/zip" id="zip_file" onChange={onLoadVCFile}/>
                                            </Form.Group>
                                        </Row>
                                        <Row className="my-2">
                                            <Form.Group>
                                                <Form.Check name="voicefilter" type="switch" checked={useVoicefilter} onChange={handleVF} label="AI가 목소리만 추출"></Form.Check>
                                            </Form.Group>
                                        </Row>
                                        {
                                            useVoicefilter ? 
                                                <Row>
                                                <InputGroup>
                                                        <Form.Select value={api_version} onChange={onChangeAPIVersion}>
                                                            <option value="" >목소리 선택</option>
                                                            <option value="2024-01-08" >2024-01-08</option>
                                                            <option value="2023-08-23" >2023-08-23</option>
                                                            <option value="2023-04-25" >2023-04-25</option>
                                                        </Form.Select>
                                                    </InputGroup>
                                                </Row>
                                                :
                                                ""
                                        }
                                        <Row>
                                            <Container className="text-secondary">
                                                <Row>
                                                    <strong className="fs-6">※ AI가 목소리만 추출 버튼이 선택되면,<br/>AI가 등록된 목소리를 구별하여 사용자가 원하는 목소리만 학습합니다.</strong>
                                                </Row>
                                            </Container>
                                        </Row>
                                        <Row className="text-end"> 
                                            <InputGroup className="d-block">
                                                <Button variant="secondary" onClick={()=>{resetConfig();}}>초기화</Button>
                                                <Button onClick={()=>{/*RequestBatch();*/}}>목소리학습 시작</Button>
                                            </InputGroup>
                                        </Row>
                                        <Row>
                                            <Container className="mt-3 text-secondary">
                                                <Row>
                                                    <strong>※ API 버전별 특징 안내</strong>
                                                </Row>
                                                <Row className="fs-6 ps-2">
                                                    <Row>
                                                        <span>2023-04-25 : 신경망 기반 인공지능이 피사체를 제외한 이미지의 배경을 제거합니다.</span>
                                                        <span>2023-08-23 : 사람의 신체가 피사체인 이미지의 배경을 제거하는데 적합합니다.</span>
                                                        <span>2024-01-08[권장] : 통계적 알고리즘이 사용된 고성능 배경제거 모델을 사용합니다. 주로 인물 사진에 적합합니다.</span>
                                                    </Row>
                                                </Row>
                                            </Container>
                                        </Row>
                                        
                                    </Form.Group>
                                </Col>
                                <Col lg={6} >
                                    <Form.Group>
                                        <Form.Label>상세로그</Form.Label>
                                        <Form.Control as="textarea"style={{backgroundColor:"white"}} rows={13} value={VC_queuelog} readOnly/>
                                    </Form.Group>
                                </Col>
                            </Row>
                        </Tab>
                        <Tab eventKey="voicefilter" title="목소리 등록"  className="p-3">
                            <Row>
                            <Col lg={6}>
                                    <Form.Group>
                                        <Row>
                                            <Form.Label>API버전 선택</Form.Label>
                                        </Row>
                                        <Row>
                                            <InputGroup>
                                                <Form.Select value={api_version} onChange={onChangeAPIVersion}>
                                                    <option value="" >API버전 선택</option>
                                                    <option value="2024-01-08" >2024-01-08</option>
                                                    <option value="2023-08-23" >2023-08-23</option>
                                                    <option value="2023-04-25" >2023-04-25</option>
                                                </Form.Select>
                                            </InputGroup>
                                        </Row>
                                        <Row>
                                            <InputGroup className="mt-4">
                                                <InputGroup.Text>목소리 이름</InputGroup.Text>
                                                <Form.Control type="text" name="string1" value="" />
                                            </InputGroup>
                                        </Row>
                                        <Row>
                                            <Form.Group className="my-3">
                                                <Form.Label>오디오 압축파일 선택</Form.Label>
                                                <Form.Control type="file" accept="application/zip" id="zip_file" onChange={onLoadVFFile}/>
                                            </Form.Group>
                                        </Row>
                                        <Row>
                                            <Container className="text-secondary">
                                                <Row>
                                                    <strong className="fs-6">※ 깨끗한 목소리를 등록해주시면 더욱 품질이 좋아집니다.</strong>
                                                </Row>
                                            </Container>
                                        </Row>
                                        <Row className="text-end"> 
                                            <InputGroup className="d-block">
                                                <Button variant="secondary" onClick={()=>{resetConfig();}}>초기화</Button>
                                                <Button onClick={()=>{/*RequestBatch();*/}}>목소리 특징 분석 시작</Button>
                                            </InputGroup>
                                        </Row>
                                        <Row>
                                            <Container className="mt-3 text-secondary">
                                                <Row>
                                                    <strong>※ API 버전별 특징 안내</strong>
                                                </Row>
                                                <Row className="fs-6 ps-2">
                                                    <Row>
                                                        <span>2023-04-25 : 신경망 기반 인공지능이 피사체를 제외한 이미지의 배경을 제거합니다.</span>
                                                        <span>2023-08-23 : 사람의 신체가 피사체인 이미지의 배경을 제거하는데 적합합니다.</span>
                                                        <span>2024-01-08[권장] : 통계적 알고리즘이 사용된 고성능 배경제거 모델을 사용합니다. 주로 인물 사진에 적합합니다.</span>
                                                    </Row>
                                                </Row>
                                            </Container>
                                        </Row>
                                    </Form.Group>
                                </Col>
                                <Col lg={6} >
                                    <Form.Group>
                                        <Form.Label>상세로그</Form.Label>
                                        <Form.Control as="textarea"style={{backgroundColor:"white"}} rows={13} value={VF_queuelog} readOnly/>
                                    </Form.Group>
                                </Col>
                            </Row>
                        </Tab>
                    </Tabs>
                </Container>
           </section>
        </>
    );

}
export default WebAPI_VC;