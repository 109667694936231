import React, { useEffect, useState } from "react"
import io from "socket.io-client"
import {Encoder} from 'libflacjs/lib/encoder.js';
//import pWorker from 'worker-loader!./audioProcessor_Worker.js';
//import { MPEGDecoder } from 'mpg123-decoder';
//import playWorker from './playWorker.js?worker';
//import * as AV from './aurora';
let buffers = []; //new Float32Array();

let full_latency = 0;
function AudioStreaming(props){ 
    
    const [msg, setMsg] = useState([]);
    
    const [PlaysampleRate, setlaySampleRate] = useState(16000);
    //let buffers = []; //new Float32Array();
    window.buffers = buffers;
    const FlacFactory = require('libflacjs');
    const Flac = FlacFactory('min.wasm');
    const exportFlacFile = require('libflacjs/lib/utils').exportFlacFile;
    
    const AV = require('./aurora');
    window['AV'] = AV;
    //const demuxer = AV_.Demuxer;
    //AV.Demuxer.register(demuxer); 
    //console.log("demuxer : ",AV.Demuxer);
    require('./flac');
    //var format = {formatID : "aac "};
    //const AACDecoder = AV.Asset.prototype.findDecoder(format);
    //console.log(AACDecoder)
    //const decoder = new MPEGDecoder();
    const [network_latency, setNetworkLatency] = useState([]);
    const [process_latency, setProcessLatency] = useState([]);
    const [srv_latency, setSrvLatency] = useState([]);
    const [postProcessing_latency, setPostProcessingLatency] = useState([]);
    let first_latency = true;
    let sampleRate = props.sampleRate;
    let bufferSize = props.bufferSize;
    var context = new AudioContext({sampleRate});
    var buffer = context.createBuffer(1, bufferSize, PlaysampleRate);
    //const pWorker = new Worker('./audioProcessor_Worker.js',{type: "module"});
    //var pWorker = new Worker('./audioProcessor_Worker.js');
    var pWorker = new Worker(new URL("./audioProcessor_Worker.js", import.meta.url));
    
    pWorker.onmessage = (data)=>{
        //console.log("EMIT!", data)
        if(data.data.buffer != null){
            
            emitBuffer(data.data);
            
        }
    }
    //const pWorker = new playWorker();
    /*const pWorker = new Worker('./playWorker.js');
    pWorker.onmessage = (data)=>{
        if(data.buffer != null && data.buffer != undefined){
            playAudio(data.buffer);
        }
        setPostProcessingLatency([...postProcessing_latency, data.postprocess_latency]);
    }*/
    //var latency= {network : 0, process: 0};
    //var network_latency = 0;//{network : 0, process: 0};
    //var process_latency = 0;
    //let srv_latency = 0;
    //let postProcessing_latency = 0;

    //let buffer = null;
    


    const convertFloat32toInt16 = (n)=>{
        var v = n < 0 ? n * 32768 : n * 32767;       // convert in range [-32768, 32767]
        return Math.max(-32768, Math.min(32768, v)); // clamp
    }
    const catchAudio = async (mediaStream)=>{
        //stream(mediaStream);
        console.log("t");
        let sampleRate = props.sampleRate;
        const context = new AudioContext({sampleRate});
        await context.resume();
        
        await context.audioWorklet.addModule("/lib/BypassProcessor.js");
        const BypassProcessor = new AudioWorkletNode(context, "bypass-processor", {processorOptions : {bufferSize: props.bufferSize}});
        BypassProcessor.port.onmessage = (e)=>{
            //console.log("bypass onmsg", {buffer: e.data})
            //console.log("play_latency : ", play_latency)
            //setTimeout(()=>{audioprocess(e);}, play_latency);
            //audioprocess(e);
            pWorker.postMessage({buffer: e.data, sampleRate:sampleRate});
            
        }
        BypassProcessor.connect(context.destination);
        
        const source = context.createMediaStreamSource(mediaStream);
        source.connect(BypassProcessor)
        
        return {source:source, processor:BypassProcessor};
    }
    async function playAudio(){
        const postprocess_start = Date.now();
        let b_length = buffers.length;
        //console.log("PLAY1", b_length)
        if(b_length <= 0)// || props.rec_state == false)
            return;
        //console.log("PLAY", b_length)
        /*while(playing==true)
            setTimeout(() => {}, 50);
        if(playing == false)
            playing= true;*/
    
        let pcmdata = buffers.shift();
        if(pcmdata.length < 1)
            return;
        //let sampleRate = props.sampleRate;
        //var context = new AudioContext({PlaysampleRate});
        
        const audioBufferSourceNode = context.createBufferSource();
        
        //var buffer = context.createBuffer(1, pcmdata.length, PlaysampleRate);
        buffer.copyToChannel(pcmdata, 0, 0);
        
        //pcmdata = null;
        
        audioBufferSourceNode.buffer = buffer;//buffers.shift();
        audioBufferSourceNode.connect(context.destination);
        /*
        if(b_length < 3){
            await props.setPlayLatency(props.play_latency+Number(props.bufferSize / props.sampleRate));
            console.log("latency to 100", b_length, props.play_latency, Number(props.bufferSize / props.sampleRate))
        }else if(b_length >= 3){
            if(props.setPlayLatency > 0){
                await props.setPlayLatency(props.play_latency-Number(props.bufferSize / props.sampleRate));
                console.log("latency to 100", b_length, props.play_latency, Number(props.bufferSize / props.sampleRate));
            }else{
                props.setPlayLatency(Number(props.bufferSize / props.sampleRate));
            }
        }*/
        
            audioBufferSourceNode.start();
            //const postprocess_latency_ = Date.now() - postprocess_start;
            
            //setPostProcessingLatency(postprocess_latency=>([...postprocess_latency, postprocess_latency_]));

    }
    function buildWaveHeader(opts) {
        //console.log(opts);
        var numFrames = opts.numFrames;
        var numChannels = opts.numChannels || 2;
        var sampleRate = opts.sampleRate || 44100;
        var bytesPerSample = opts.bytesPerSample || 2;
        var blockAlign = numChannels * bytesPerSample;
        var byteRate = sampleRate * blockAlign;
        var dataSize = numFrames * blockAlign;
    
        var buffer = new ArrayBuffer(44);
        var dv = new DataView(buffer);
    
        var p = 0;
    
        function writeString(s) {
            for (var i = 0; i < s.length; i++) {
                dv.setUint8(p + i, s.charCodeAt(i));
            }
            p += s.length;
        }
    
        function writeUint32(d) {
            dv.setUint32(p, d, true);
            p += 4;
        }
    
        function writeUint16(d) {
            dv.setUint16(p, d, true);
            p += 2;
        }
    
        writeString('RIFF');              // ChunkID
        writeUint32(dataSize + 36);       // ChunkSize
        writeString('WAVE');              // Format
        writeString('fmt ');              // Subchunk1ID
        writeUint32(16);                  // Subchunk1Size
        writeUint16(1);                   // AudioFormat
        writeUint16(numChannels);         // NumChannels
        writeUint32(sampleRate);          // SampleRate
        writeUint32(byteRate);            // ByteRate
        writeUint16(blockAlign);          // BlockAlign
        writeUint16(bytesPerSample * 8);  // BitsPerSample
        writeString('data');              // Subchunk2ID
        writeUint32(dataSize);            // Subchunk2Size
    
        return buffer;
    }

    function emitBuffer(e){
        //console.log("EMIT!",e);
        //const processing_start = Date.now();
        //let data =  e.data; //Float32Array
        
        /*
        //mp3 compressing
        var len = data.length; var i=0;
        let int16data = new Int16Array(len);
        while(i<len){
            let n = data[i] < 0 ? data[i] * 32768 : data[i] * 32767;  
            int16data[i] =  n; //* 32768;//convertFloat32toInt16(data[i++])
            i++;
        }
        data = mp3encoder.encodeBuffer(int16data);
        */

        /*
        // WAV PCM 16bit with header
        var opt = {numFrames : data.length, numChannels :1, sampleRate : props.sampleRate, bytesPerSample : 2}
        var header = new Uint8Array(buildWaveHeader(opt));
        var new_data = new Int16Array(header.buffer);
        new_data = new Int16Array([...new_data, ...data]);
        */
       /*
        var len = data.length; var i=0;
        while(i<len){
            data[i] = data[i]/32768;
            i++;
        }
        buffers.push(data);
        //console.log(first_latency);
        //playAudio();
        return;
        */
        //flac compressing
        /*
        let asset = AV.Asset.fromBuffer(e.buffer);
                asset.decodeToBuffer(function(buffer){
                    //console.log("Buffer PUSH!", buffers.length);
                    //pWorker.postMessage({buffer: buffer});
                    //if(buffers.length > 2)
                    //    buffers.clear = [];
                    buffers.push(buffer);
                    //console.log(first_latency);
                    playAudio();
                    
                    /*
                    if(first_latency == true){
                        first_latency = false;
                        setTimeout(()=>{playAudio(postprocess_start);}, 30);
                    }else{
                        playAudio(postprocess_start);
                    }*/
                    
        //        });
        //return;
        
        //const network_start = Date.now();
        props.socket.emit('request', {data : e.buffer, sr:props.sampleRate}, ()=>{
            //data = null;
            //int16data = null;
            
            //const network_latency__ = Date.now() - network_start
 
            //setNetworkLatency(network_latency=>([...network_latency, network_latency__]));
            //setProcessLatency(process_latency=>([...process_latency, process_latency__]));
            //pWorker.terminate();
        });
        
        //console.log("emit!!")
    }

    async function audioprocess(e){
        const processing_start = Date.now();
        let data =  e.data; //Float32Array
        
        /*
        //mp3 compressing
        var len = data.length; var i=0;
        let int16data = new Int16Array(len);
        while(i<len){
            let n = data[i] < 0 ? data[i] * 32768 : data[i] * 32767;  
            int16data[i] =  n; //* 32768;//convertFloat32toInt16(data[i++])
            i++;
        }
        data = mp3encoder.encodeBuffer(int16data);
        */

        /*
        // WAV PCM 16bit with header
        var opt = {numFrames : data.length, numChannels :1, sampleRate : props.sampleRate, bytesPerSample : 2}
        var header = new Uint8Array(buildWaveHeader(opt));
        var new_data = new Int16Array(header.buffer);
        new_data = new Int16Array([...new_data, ...data]);
        */
       /*
        var len = data.length; var i=0;
        while(i<len){
            data[i] = data[i]/32768;
            i++;
        }
        buffers.push(data);
        //console.log(first_latency);
        //playAudio();
        return;
        */
        //flac compressing
        
        const encoder = new Encoder(Flac, {
            sampleRate: props.sampleRate,         // number, e.g. 44100
            channels: 1,             // number, e.g. 1 (mono), 2 (stereo), ...
            bitsPerSample: 16,   // number, e.g. 8 or 16 or 24
            compression: 8,  // number, value between [0, 8] from low to high compression
            verify: true,                   // boolean (OPTIONAL)
            isOgg: false                    // boolean (OPTIONAL), if encoded FLAC should be wrapped in OGG container
          });
        encoder.encode(data);
        encoder.encode();//<- finalize encoding by invoking encode() without arguments
        const encData = encoder.getSamples();
        const metadata = encoder.metadata;
        //console.log(encData);
        //console.log(metadata);
        encoder.destroy();
        const flacBlob = exportFlacFile([encData], metadata,false);

        const process_latency__ = Date.now() - processing_start;
        /*
        var asset = AV.Asset.fromBuffer(await flacBlob.arrayBuffer());
                asset.decodeToBuffer(function(buffer){
                    //console.log("Buffer PUSH!", buffers.length);
                    //pWorker.postMessage({buffer: buffer});
                    buffers.push(buffer);
                    //console.log(first_latency);
                    playAudio();
                    
                    /*
                    if(first_latency == true){
                        first_latency = false;
                        setTimeout(()=>{playAudio(postprocess_start);}, 30);
                    }else{
                        playAudio(postprocess_start);
                    }*/
                    
           //     });
        //return;

        
        const network_start = Date.now();
        
        props.socket.emit('request', {data : null, sr:props.sampleRate}, ()=>{
            data = null;
            //int16data = null;
            
            const network_latency__ = Date.now() - network_start
 
            setNetworkLatency(network_latency=>([...network_latency, network_latency__]));
            setProcessLatency(process_latency=>([...process_latency, process_latency__]));
        });
        
        //console.log("emit!!")
    }

    
    
    const connectSocket = (wsinfo)=>{
        //const io_ = io.connect("https://api1.namisnt.com/api/namivc/ws/socketio", {path:"/api/namivc/ws/socket.io/", origin:"https://api1.namisnt.com", transports:['websocket']});
        const io_ = io.connect("https://"+wsinfo.server+"/api/namivc/ws/socketio", {path:"/api/namivc/ws/socket.io/", origin:"http://bitcoin-kw.namisnt.com:8081/", transports:['websocket'], auth:{Authorization:"Bearer "+wsinfo.wstoken, "Nami-ws-token":1}});
        io_.on('connect', function (){
            console.log('connected: ');
            setInterval(() => {
                const start = Date.now();
              
                io_.emit("ping", () => {
                  ////const duration = Date.now() - start;
                  ////props.setPing(duration);
                  //console.log(network_latency, process_latency);
                  //setPostProcessingLatency([0]);
                  //console.log("ping : "+duration);
                });
              }, 1000);
        });
        io_.on("response", async (data)=>{
            //setMsg([...msg, data.data]);
            if(data.type==1){
                
                //console.log(buffers.length)
                //console.log("server processing time : "+data.process_time*1000+"ms");
                //var arr = new Float32Array(util.decodeBase64(data.data).buffer)
                ////setSrvLatency(srv_latency=>([...srv_latency,(data.process_time*1000)]));
                
                //srv_latency += data.process_time*1000;
                /*
                //32bit PCM
                var arr = new Float32Array(new Uint8Array(data.data).buffer);
                buffers.push(arr);
                playAudio();
                */

                /*
                //16bit Integer PCM
                var float16_arr = new Int16Array(data);
                var len=float16_arr.length; var i=0;
                let float32_arr = new Float32Array(bufferSize);
                while(i<len){
                    float32_arr[i] = float16_arr[i++]/32768;
                    //console.log(parseFloat(float16_arr[i-1]))
                }
                //console.log(arr);
                buffers.push(float32_arr);
                playAudio();
                */
                
                /*
                // mp3 
                var arr = new Uint8Array(data.data);
                const {channelData, samplesDecoded, sampleRate, errors} = decoder.decode(arr);
                //console.log(errors)
                //if(buffer == null)
                
                buffers.push(channelData[0]);
                arr = null;
                decoder.reset();
                playAudio()
                */
                //aac
                
                let asset = AV.Asset.fromBuffer(data.data);
                asset.decodeToBuffer(function(buffer){
                    //console.log("Buffer PUSH!", buffers.length);
                    //pWorker.postMessage({buffer: buffer});
                    //if(buffers.length > 2)
                    //    buffers.clear = [];
                    buffers.push(buffer);
                    //console.log(first_latency);
                    playAudio();
                    
                    /*
                    if(first_latency == true){
                        first_latency = false;
                        setTimeout(()=>{playAudio(postprocess_start);}, 30);
                    }else{
                        playAudio(postprocess_start);
                    }*/
                    
                });
                //console.log("pushed !", buffers.length);
                //asset.decode
                
                /*
                //lena mp3 test
                decoders.mp3();
                var buf = decode(buffer).then((bf)=>{
                    var pcm_arr = new Float32Array(bf.length);
                    bf.copyFromChannel(pcm_arr, 0, 0);
                    buffers.push(pcm_arr);
                    playAudio();
                });
                */
            }
        })
        props.setSocket(io_);
        //return ()=>io_.close();
    }
    useEffect(()=>{
        
        //MP3 Decode
        /*decoder.ready.then(()=>{
            props.setDecoderReady(true);
        });*/
    })
    useEffect(()=>{
        //console.log("start PLAY !");
        //setTimeout(async ()=>{await pWorker.postMessage({start:true})}, 5000)
        
    }, [])
    useEffect(()=>{

    },[props.decoderReady]);
    useEffect(()=>{
        //console.log("something changed", srv_latency, network_latency)
        let len = props.sampleRate / props.bufferSize;
        if(network_latency.length > len){
            props.setNetworkLatency(()=>{
                let val =  network_latency.reduce((a,v)=>a=a+v, 0)/(network_latency.length > 0 ? network_latency.length : 1);
                return val;
              });
              setNetworkLatency(prev=>([]));
        }
        if(process_latency.length > len){
            props.setProcessLatency(()=>{
                let val =  process_latency.reduce((a,v)=>a=a+v, 0)/(process_latency.length > 0 ? process_latency.length : 1);
                
                return val;
              });
              setProcessLatency(prev=>([]));
        }
        if(srv_latency.length > len){
            props.setSrvLatency(()=>{
                let val =  srv_latency.reduce((a,v)=>a=a+v, 0)/(srv_latency.length > 0 ? srv_latency.length : 1);
                return val;
              });
              setSrvLatency(prev=>([]));
        }
        if(postProcessing_latency.length > len){
            props.setPostProcessingLatency(()=>{
                let val =  postProcessing_latency.reduce((a,v)=>a=a+v, 0)/(postProcessing_latency.length > 0 ? postProcessing_latency.length : 1);
                //console.log(val);
                return val;
              });
              setPostProcessingLatency(prev=>([]));
        }
        full_latency = Number(props.network_latency)+Number(props.process_latency)+Number(props.postProcessing_latency);
        
    }, [srv_latency, network_latency, srv_latency])
    useEffect(()=>{
        console.log("stream : "+props.stream==null);
        if(props.stream==null)
            return;
        console.log("set Rec Components start");
        connectSocket(props.wsinfo);
        /*
        setInterval(()=>{
            //console.log("DFD", play_latency*1000)
            playAudio();
            //play_latency = Number(0);
        }, Number(props.play_latency*1000));
        */
    }, [props.stream]);
    /*
    useEffect(()=>{
        console.log("stream : "+rec_components.source == null+rec_components.processor == null);
        if(rec_components.source == null || rec_components.processor == null)
            return ;     
        console.log("Connect socket start");
        
    }, [rec_components.source, rec_components.processor]);
    */
    useEffect(()=>{
        if(props.socket != null){
            catchAudio(props.stream).then((components)=>{
                props.setRecComponents(components);
                //play_loop();
                //requestAnimationFrame(play_loop);
            });
        }
    }, [props.socket])
    /*
    function play_loop(){
        playAudio();
        return requestAnimationFrame(play_loop);
        let latency = bufferSize*1000/sampleRate;
        setTimeout(function(){
            setInterval(function(){
                playAudio();
            }.bind(this), latency);
        }, latency);
        
          
    }
*/
}
export default AudioStreaming;